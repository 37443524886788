<template>
  <div class="bg">
    <div class="header">
      <div class="headerL">
        <router-link to="/">
          <img src="@/assets/image/logo.png" alt="logo" />
        </router-link>
      </div>
      <div class="title">外研教学评系统隐私政策摘要</div>
    </div>
    <div class="main">
      <p>
        外研教学评系统（以下简称“我们”)高度重视保护用户（或称“您”）的个人信息及隐私安全。本摘要旨在以直观、简洁的方式向您介绍《外研教学评系统隐私政策》（以下简称“
        <span class="weight">隐私政策</span>”）的内容。
      </p>
      <br />
      <div class="weight">一、我们如何收集和使用个人信息</div>
      <p class="pl1">1.1.用户注册与登录</p>
      <p class="pl1">
        1.1.1.如果您是自行注册成为用户的，
        <span class="weight underL"
          >为了创建账号，我们需要收集您的手机号码、密码、验证码，</span
        >如您不提供以上信息，您将无法注册我们的产品；<span class="weight underL"
          >为完成账号登录，我们需要收集您的账号或手机号或邮箱、密码，</span
        >如果您拒绝提供此类信息，则无法完成账号注册，也无法登录使用本产品。
      </p>
      <p class="pl1">
        1.1.2.如果您是教师用户，您可以在登录本产品悦教大讲堂模块后
        <span class="weight underL"
          >补充您的个人信息，包括性别、生日、所教学段、单位、工作年限、关注领域。</span
        >如果您不提供此类信息，将不会影响您使用外研教学评的基本功能，但与该等信息相关的一些扩展性功能可能会无法使用。
      </p>
      <p class="pl1">
        1.1.3.如果您是教师用户，为了帮助您进行班级管理、考务管理，方便您安排考试，进行作答数据的统计分析，并生成报告等，或进行相关教学学习，<span
          class="weight underL"
          >我们需要收集您的姓名、班级、年级、学校。</span
        >如果您拒绝提供此类信息，可能无法使用相应的功能，但不会影响您使用外研教学评的基本功能。
      </p>
      <p class="pl1">
        1.1.4.如果您作为教师管理员，需要为学校的学生统一生成学生的个人账号的，您需要按照页面提示
        <span class="weight underL"
          >提供学生姓名、学校名称、班级、所属年级，</span
        >方能生成学生的个人账号。
        您需要在收集学生个人信息前征得其及其监护人的同意。如果您拒绝提供此类信息，则无法完成学生账号的注册，学生将无法使用该账号登录使用本产品。
      </p>
      <p class="pl1">
        1.1.5.如果您是学生用户，且是通过学校给您分配账号从而登录本产品成为用户的，<span
          class="weight underL"
        >
          您知悉并认可，在您登录本产品前您所在的学校工作人员已将您的姓名、班级、学校名称、所属年级、专业等个人信息提供给我们，由我们导入系统中，以确保您能正常登录并使用本产品，您同意本隐私政策，即代表您同意并追认同意学校有权提供您的个人信息给我们，我们可依本政策的约定处理您的个人信息。
        </span>
      </p>
      <p class="pl1">1.2.找回密码</p>
      <p class="pl1">
        为找回您的密码，我们需要收集您的<span class="weight underL"
          >手机号码或邮箱、验证码，</span
        >如您不能提供，我们将无法为您找回密码。
      </p>
      <p class="pl1">1.3.下单与支付</p>
      <p class="pl1">
        1.3.1.为了向您提供网络支付购买付费服务，校验下单账号和支付账号是否一致，我们会收集您的
        <span class="weight underL">微信账号和支付宝账号</span
        >，但不会存储您的这些账号。
      </p>
      <p class="pl1">
        1.3.2.为了确保交易安全，保障购买成功，我们会向第三方支付机构共享您的头像、账号名、订单支付相关信息、设备信息、系统信息及网络信息，如需全面了解我们与第三方共享您个人信息的情况，您可查看文末的附件《外研教学评个人信息第三方共享清单》。
      </p>
      <p class="pl1">1.4.客服、反馈及其他功能</p>
      <p class="pl1">
        1.4.1.为了保障您的账号与系统安全，解决您在使用我们产品过程中的问题时，我们会保存您所反馈的问题记录，包括
        账号信息（用户ID)、反馈信息（文字、图片、文件）。
      </p>
      <p class="pl1">
        1.4.2.如果是为了向您提供售后服务，确保您向客服反馈的信息能准确有效处理，我们还需要收集您的账号信息（用户ID)、
        订单信息、联系方式（手机号码）。
      </p>
      <p class="pl1">1.5.运营和产品安全维护</p>
      <p class="pl1">
        1.5.1我们将收集您的如下信息，以便为您提供持续稳定的服务支持（如数据的实时同步等），并识别账号异常状态、了解产品适配性、保障本产品服务的网络及运营安全，以维护前述基本功能的正常运行。我们会收集您的
        设备信息（包括设备型号、设备类型、设备序列号、设备识别码（MAC地址等）、设备厂商、操作系统版本、应用列表、设备设置基本信息）、日志信息（User-Agent、IP地址、访问日期和时间、您访问的记录）、网络信息（包括使用的移动网络运营商网络数据信息、WI-FI信号信息、宽带数据信息）。
      </p>
      <p class="pl1">
        1.5.2请注意，单独的设备信息、服务日志信息是无法识别特定自然人身份的信息。
        如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将这类信息做匿名化、去标识化处理。
      </p>
      <p class="pl1">
        1.6.当您使用本产品网站或移动端提供的产品或服务时，我们会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。包括您的搜索和浏览记录、学习记录、测试记录、测试结果、IP地址、WIFI网络、访问日期和时间等。
      </p>
      <div>2.当您使用以下功能时，我们需要您提供相应的权限许可</div>
      <p>
        2.1.当您需要使用口语测评功能的，我们需要获取您的麦克风权限，帮助您进行口语测评、参加测评考试。
      </p>

      <div class="weight">
        二、我们如何委托处理、共享、转让、公开披露您的个人信息
      </div>
      <p>
        为了向您提供我们的产品或服务，我们需要在必要的目的和范围内与第三方委托处理、共享、转让或公开披露您的个人信息，对该等第三方，我们会根据法律规定与其签署相关协议，要求他们按照我们的要求、隐私政策以及其他任何相关的保密和安全措施来处理您的个人信息。如根据法律规定需要取得您的单独同意的，我们将会采取措施取得您的单独同意。
      </p>

      <div class="weight">三、您对您的个人信息的权利</div>
      <p>
        我们保障您对个人信息所享有的
        <span class="weight"
          >查询、更正、删除、改变授权、注销账号、获取个人信息副本等</span
        >
        权利，以使您拥有充分的能力保障您的隐私和安全。具体操作您可以阅读
        <router-link target="_blank" to="/privacy">
          《外研教学评系统隐私政策》
        </router-link>
        第五部分。
      </p>

      <div class="weight">四、个人信息的储存</div>
      <p>
        我们会按照适用的中国法律法规的要求，将收集的个人信息存储在我们位于中国境内的服务器上。未经您的同意，我们不会将您的信息传输至境外。如果我们向境外传输，我们将会遵循相关国家规定或者征求您的同意。对您的个人信息的保存期限会限制在为实现目的所必需的期限和法律法规要求的期限内。
      </p>

      <div class="weight">五、我们如何保护未成年人的信息</div>
      <p>
        如您为14周岁以下的未成年人，请务必让您的监护人仔细阅读
        <router-link target="_blank" to="/childrenPrivacy">
          《儿童隐私保护声明》
        </router-link>
        ，并在征得您的监护人同意的前提下使用我们的服务或向我们提供个人信息。
      </p>

      <div class="weight">六、如何联系我们</div>
      <p>
        您在使用外研教学评系统产品及/或服务的过程中，如果有任何的疑问或建议，或您认为您的个人信息没有得到隐私政策规定的保护，
        您可以通过以下方式联系我们，我们将真诚地处理您的投诉及建议。
      </p>
      <p>公司名称：外语教学与研究出版社有限责任公司</p>
      <p>联系地址：北京市西三环北路19号外研社大厦研发中心</p>
      <p>联系邮箱：yanfa@fltrp.com</p>
      <p>客服电话：010-88819743</p>
      <p>（悦测评、外研拼读、悦教大讲堂）邮箱：wangjinmeng@fltrp.com</p>
      <p>（悦测评、外研拼读、悦教大讲堂）电话：010-88819188</p>
      <p>
        如你需要了解完整的信息，请您查阅<router-link
          target="_blank"
          to="/privacy"
        >
          《外研教学评系统隐私政策》
        </router-link>
      </p>
    </div>
  </div>
</template>
<style scoped>
p {
  text-indent: 2rem;
}
.header {
  background-color: #47475c;
  color: #ffffff;
  overflow: hidden;
}
.headerL {
  float: left;
  color: #ffffff;
  overflow: hidden;
}
.headerL img {
  height: 3.2rem;
  padding: 0.3rem;
  margin-left: 0.5rem;
}
.title {
  font-size: 2rem;
  text-align: center;
  line-height: 60px;
  width: 800px;
  margin: 0 auto;
}
.bg {
  background-color: #eee;
}
.main {
  padding: 1.786em 2.5em;
  background-color: #fff;
  max-width: 1000px;
  margin: 2rem auto;
  line-height: 2.2em;
  margin-bottom: 0;
}
.h1 {
  font-weight: 500;
  font-size: 1.5rem;
  padding: 1.5rem 0;
}
.weight {
  font-weight: 600;
}
ul {
  list-style-type: disc;
  padding-left: 3rem;
}
.pl1 {
  padding-left: 1rem;
}
.underL {
  text-decoration: underline;
}
</style>
